// import { faDumpster } from '@/node_modules/@fortawesome/free-solid-svg-icons/index'
import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    collaboratorsRpsExpires: [],
    iconLoading: true,
    asepeyoGraphicIndex: [],
    asepeyoGraphicIndexAutonomousCommunity: [],
    collaboratorMedicalInfo: [],
    asepeyoBySiteData: [],
    asepeyoCollaboratorBySiteData: [],
    asepeyoCollaboratorBySiteDataGraphicDirector: [],
    rpReportingSitesStatus: [],
    rpReportingPercentBySite: [],
    myDReportingIndex: [],
    rpReportingRpStatusBySite: [],
    rpStateAllCountry: [],
    exportDataBySite: null,
    exportDataByCollaborator: null,
    allCountryRpIntegrationStatus: null,
    preventRegisterStatus: [],
    preventRegisterStatusBySite: [],
    preventRegisterStatusByCommunity: [],
  }
}

export const mutations = {
  updateMyDReportingIndex(state, myDReportingIndex) {
    state.myDReportingIndex = myDReportingIndex
  },
  updateCollaboratorsRpsExpires(state, collaboratorsRpsExpires) {
    state.collaboratorsRpsExpires = collaboratorsRpsExpires
  },
  updateAsepeyoGraphicIndex(state, asepeyoGraphicIndex) {
    state.asepeyoGraphicIndex = asepeyoGraphicIndex
  },
  updateAsepeyoGraphicIndexAutonomousCommunity(
    state,
    asepeyoGraphicIndexAutonomousCommunity
  ) {
    state.asepeyoGraphicIndexAutonomousCommunity =
      asepeyoGraphicIndexAutonomousCommunity
  },
  updateCollaboratorMedcalInfo(state, collaboratorMedicalInfo) {
    state.collaboratorMedicalInfo = collaboratorMedicalInfo
  },
  updateAsepeyoBySiteData(state, asepeyoBySiteData) {
    state.asepeyoBySiteData = asepeyoBySiteData
  },
  updateAsepeyoCollaboratorBySiteData(state, asepeyoCollaboratorBySiteData) {
    state.asepeyoCollaboratorBySiteData = asepeyoCollaboratorBySiteData
  },
  updateAsepeyoCollaboratorBySiteDataGraphicDirector(
    state,
    asepeyoCollaboratorBySiteDataGraphicDirector
  ) {
    state.asepeyoCollaboratorBySiteDataGraphicDirector =
      asepeyoCollaboratorBySiteDataGraphicDirector
  },
  updateRpReportingSitesStatus(state, rpReportingSitesStatus) {
    state.rpReportingSitesStatus = rpReportingSitesStatus
  },
  updateRpReportingRpStatusBySite(state, rpReportingRpStatusBySite) {
    state.rpReportingRpStatusBySite = rpReportingRpStatusBySite
  },
  updateRpStateAllCountry(state, rpStateAllCountry) {
    state.rpStateAllCountry = rpStateAllCountry
  },
  updateExportDataBySite(state, exportDataBySite) {
    state.exportDataBySite = exportDataBySite
  },
  updateExportDataByCollaborator(state, exportDataByCollaborator) {
    state.exportDataByCollaborator = exportDataByCollaborator
  },
  updateAllCountryRpIntegrationStatus(state, allCountryRpIntegrationStatus) {
    state.allCountryRpIntegrationStatus = allCountryRpIntegrationStatus
  },
  updatePreventRegisterStatus(state, preventRegisterStatus) {
    state.preventRegisterStatus = preventRegisterStatus
  },
  updatePreventRegisterStatusBySite(state, preventRegisterStatusBySite) {
    state.preventRegisterStatusBySite = preventRegisterStatusBySite
  },
  updatePreventRegisterStatusByCommunity(state, preventRegisterStatusByCommunity) {
    state.preventRegisterStatusByCommunity = preventRegisterStatusByCommunity
  },

}
export const state = getDefaultState()

export const actions = {
  // COLLABORATOR RP EXPIRES
  // GET
  async getCollaboratorsRpsExpires(store, site) {
    const url = `${BASE}/api/v1/collaboratorRp.expires/${site}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)
      if (response.data.error) {
        store.commit('updateCollaboratorsRpsExpires', [])
      } else {
        store.commit('updateCollaboratorsRpsExpires', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // ASEPEYO GRAPHIC INDEX DATA
  // GET
  async getAsepeyoGraphicIndex(store, year) {
    const siteCode = null
    const url = `${BASE}/api/v1/asepeyoGrafhics.index/${siteCode}/${year}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)
      if (response.data.error) {
        store.commit('updateAsepeyoGraphicIndex', [])
      } else {
        store.commit('updateAsepeyoGraphicIndex', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },

  // ASEPEYO AUTONOMOUSCOMUNITY GRAPHIC DATA
  // GET
  async getAsepeyoGraphicIndexAutonomousCommunity(store, year) {
    const url = `${BASE}/api/v1/asepeyoGrafhics.autonomousCommunity/${year}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)
      if (response.data.error) {
        store.commit('updateAsepeyoGraphicIndexAutonomousCommunity', [])
      } else {
        store.commit(
          'updateAsepeyoGraphicIndexAutonomousCommunity',
          response.data
        )
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },

  // ASEPEYO COLLABORATOR MEDICAL INFO
  // GET
  async getAsepeyoCollaboratorMedicalInfo(store, userName) {
    const url = `${BASE}/api/v1/reporting.collaboratorMedicalInfo/${userName}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateCollaboratorMedcalInfo', [])
      } else {
        store.commit('updateCollaboratorMedcalInfo', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },

  // ASEPEYO BY SITE DATA
  // GET
  async getAsepeyoBySiteData(store, year) {
    const url = `${BASE}/api/v1/reporting.asepeyoBySiteData/${year}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateAsepeyoBySiteData', [])
      } else {
        store.commit('updateAsepeyoBySiteData', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // ASEPEYO COLLABORATOR BY SITE DATA
  // GET
  async getAsepeyoCollaboratorBySiteData(store, payload) {
    const url = `${BASE}/api/v1/reporting.asepeyoCollaboratorBySiteData/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateAsepeyoCollaboratorBySiteData', [])
      } else {
        store.commit('updateAsepeyoCollaboratorBySiteData', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // ASEPEYO COLLABORATOR BY SITE DATA GRAPHIC FOR DIRECTOR
  // GET
  async getAsepeyoCollaboratorForDirectorGraphicBySiteData(store, payload) {
    const url = `${BASE}/api/v1/asepeyoGrafhics.index/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateAsepeyoCollaboratorBySiteDataGraphicDirector', [])
      } else {
        store.commit(
          'updateAsepeyoCollaboratorBySiteDataGraphicDirector',
          response.data
        )
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // MYD REPORTING INDEX
  // GET
  async getMyDReportingIndex(store, payload) {
    const url = `${BASE}/api/v1/mydReporting.bySite/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateMyDReportingIndex', [])
      } else {
        store.commit('updateMyDReportingIndex', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // RP STATE BY SITE
  // GET
  async getRpStateBySite(store, payload) {
    const url = `${BASE}/api/v1/reporting.rpReportingRpStatusBySite/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateRpReportingRpStatusBySite', [])
      } else {
        store.commit('updateRpReportingRpStatusBySite', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // RP STATE BY SITE
  // GET
  async getRpStateAllCountry(store, payload) {
    const url = `${BASE}/api/v1/reporting.rpReportingRpStatusBySite/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateRpStateAllCountry', [])
      } else {
        store.commit('updateRpStateAllCountry', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // RP STATE BY SITE Data for excel
  // GET
  async getRpStatusDataForExcel(store, payload) {
    const url = `${BASE}/api/v1/reporting.exportDataBySite/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateExportDataBySite', [])
      } else {
        store.commit('updateExportDataBySite', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // RP STATE BY COLLABORATOR Data for excel
  // GET
  async getRpStatusDataForExcelByCollaborator(store, payload) {
    const url = `${BASE}/api/v1/reporting.exportDataByColl/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      if (response.data.error) {
        store.commit('updateExportDataByCollaborator', [])
      } else {
        store.commit('updateExportDataByCollaborator', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
  // ALL COUNTRY RP INTEGRATION STATE DATA FOR REPORTING
  // GET
  async getAllCountryRpIntegrationStatus(store) {
    const url = `${BASE}/api/v1/mydReporting.country`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      store.commit('updateAllCountryRpIntegrationStatus', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    } finally {
      store.commit('common/updateIconLoading', false, { root: true })
    }
  },
  // ALL COUNTRY PREVENT REGISTER DOCUMENT STATE
  // GET
  async getPreventRegisterDocumentStatus(store) {
    const url = `${BASE}/api/v1/reporting.preventRegisterStatus`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      store.commit('updatePreventRegisterStatus', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    } finally {
      store.commit('common/updateIconLoading', false, { root: true })
    }
  },
  async getPreventRegisterDocumentStatusBySite(store, payload) {
    const url = `${BASE}/api/v1/reporting.preventRegisterStatusBySite/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      store.commit('updatePreventRegisterStatusBySite', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    } finally {
      store.commit('common/updateIconLoading', false, { root: true })
    }
  },
  async getPreventRegisterDocumentStatusByCommunity(store, payload) {
    const url = `${BASE}/api/v1/reporting.preventRegisterStatusCommunity/${payload}`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)

      store.commit('updatePreventRegisterStatusByCommunity', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    } finally {
      store.commit('common/updateIconLoading', false, { root: true })
    }
  },
}
