import store from '@state/store'
import { siteClearance } from '@state/helpers'
import isAuthorized  from '@utils/isAuthorized'

export default [
  {
    path: '/',
    redirect: (to, from, next) => {
      const actorRoles = store.state.auth.actorRoles
      const currentUser = store.state.auth.currentUser

      if (currentUser && !actorRoles) {
        return {
          name: 'collaboratorNotFound',
        }
      }

      if (!actorRoles) return '/logout'

      if (isAuthorized(actorRoles, siteClearance.admin)) {
        return {
          name: 'home',
        }
      } else if (
        isAuthorized(actorRoles, siteClearance.director) ||
        isAuthorized(actorRoles, siteClearance.security) ||
        isAuthorized(actorRoles, siteClearance.responsible)
      ) {
        return {
          name: 'home-site',
          params: {
            siteCode:
              actorRoles.length > 1
                ? actorRoles[1].site.code
                : actorRoles[0].site.code,
          },
        }
      } else if (isAuthorized(actorRoles, siteClearance.collaborator)) {
        return {
          name: 'collaborator-info',
          params: {
            siteCode:
              actorRoles.length > 1
                ? actorRoles[1].site.code
                : actorRoles[0].site.code,
            username: currentUser.sub,
          },
        }
      } else {
        return {
          name: 'notAuthorized',
        }
      }
    },
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
      ],
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/src/router/views/home/spain-map.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/support',
    name: 'support',
    beforeEnter() {
      window.location.href = 'https://support.decathlon.net/saw/ess/offeringPage/57523871?query=prl'
    },
  },
  {
    path: '/personal-data',
    name: 'personalData',
    component: () => import('@/src/router/views/collaborators/collaborator-personal-data.vue'),
    beforeEnter: (to, from, next) => {
      const actorRoles = store.state.auth.actorRoles

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }

      // CHECK USER INFO, collaborator only can see his own data
      if (
        !isAuthorized(actorRoles, siteClearance.admin) &&
        !isAuthorized(actorRoles, siteClearance.director) &&
        store.state.auth.currentUser.sub !== undefined &&
        to.params.username !== undefined &&
        store.state.auth.currentUser.sub !== to.params.username
      ) {
        next({ name: 'notAuthorized' })
      }
      // continue...
      next()
    },
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.director],
    },
  },
  {
    path: '/collaborators',
    component: () => import('@views/collaborators/collaborators-list.vue'),
    beforeEnter: (to, from, next) => {
      const actorRoles = store.state.auth.actorRoles

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }

      if (!isAuthorized(actorRoles, siteClearance.admin)) {
        next({ name: 'notAuthorized' })
      }
      // continue...
      next()
    },
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/collaborators/:username',
    name: 'admin-collaborator-info',
    component: () => import('@views/collaborators/collaborator-info.vue'),
    beforeEnter: (to, from, next) => {
      const actorRoles = store.state.auth.actorRoles

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }

      // CHECK USER INFO, collaborator only can see his own data
      if (
          !isAuthorized(actorRoles, siteClearance.admin) &&
          !isAuthorized(actorRoles, siteClearance.director) &&
          !isAuthorized(actorRoles, siteClearance.security) &&
          store.state.auth.currentUser.sub !== undefined &&
          to.params.username !== undefined &&
          store.state.auth.currentUser.sub !== to.params.username
      ) {
        next({ name: 'notAuthorized' })
      }
      // continue...
      next()
    },
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
      ],
    },
  },
  {
    path: '/collaborators/:username/assignment-rps',
    name: 'admin-collaborator-assignment-rps',
    component: () => import('@views/collaborators/collaborator-assignment-rps.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/collaborators/register',
    name: 'collaborator-register',
    component: () => import('@views/collaborators/collaborator-register.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/multiple-validation',
    name: 'multiple-rp-validation',
    component: () => import('@views/collaborator-rp/multiple-rp-validation.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/planning',
    name: 'planning',
    component: () => import('@views/planning/global-planning/global-planning.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },

  {
    path: '/:siteCode/collaborators',
    name: 'home-site',
    component: () => import('@views/collaborators/collaborators-list.vue'),
    beforeEnter: (to, from, next) => {
      const actorRoles = store.state.auth.actorRoles

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }

      // CHECK USER INFO. Only admin can watch all sites. Each user watch his own data
      if (
          !isAuthorized(actorRoles, siteClearance.admin) &&
          actorRoles[1].site.code !== to.params.siteCode
      ) {
        next({ name: 'notAuthorized' })
      }
      // continue...
      next()
    },
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },

  {
    path: '/:siteCode/collaborators/:username',
    name: 'collaborator-info',
    component: () => import('@views/collaborators/collaborator-info.vue'),
    beforeEnter: (to, from, next) => {
      const actorRoles = store.state.auth.actorRoles

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }

      // CHECK USER INFO, collaborator only can see his own data
      if (
          !isAuthorized(actorRoles, siteClearance.admin) &&
          !isAuthorized(actorRoles, siteClearance.director) &&
          !isAuthorized(actorRoles, siteClearance.security) &&
          !isAuthorized(actorRoles, siteClearance.responsible) &&
          store.state.auth.currentUser.sub !== undefined &&
          to.params.username !== undefined &&
          store.state.auth.currentUser.sub !== to.params.username
      ) {
        next({ name: 'notAuthorized' })
      }
      // continue...
      next()
    },
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.security,
        ...siteClearance.collaborator,
      ],
    },
  },
  {
    path: '/collaborators/:username/assignment-rps',
    name: 'collaborator-assignment-rps',
    component: () => import('@views/collaborators/collaborator-assignment-rps.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/collaborators/:username/collaborator-change-role',
    name: 'collaborator-change-role',
    component: () => import('@views/collaborators/collaborator-role.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
      ],
    },
  },
  {
    path: '/collaborators/:username/collaborator-assing-sites',
    name: 'collaborator-assing-site',
    component: () => import('@views/collaborators/collaborator-assing-sites-signer.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.director],
    },
  },

  {
    path: '/asepeyo-reporting-index',
    name: 'asepeyo-reporting-index',
    component: () => import('@views/reporting/asepeyo-reporting-index.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/myd-reporting-index',
    name: 'myd-reporting-index',
    component: () => import('@views/reporting/myd-reporting-index.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/rp-reporting-collaborator-site/:siteCode',
    name: 'rp-reporting-collaborator-site',
    component: () => import('@views/reporting/rp-reporting-collaborator-site.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
      ],
    },
  },

  {
    path: '/rp-reporting-state',
    name: 'rp-reporting-state',
    component: () => import('@views/reporting/rp-reporting-state.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/rp-reporting-index',
    name: 'rp-reporting-index',
    component: () => import('@views/reporting/rp-reporting-index.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/asepeyo-collaborator-medical-info/:username',
    name: 'asepeyo-reporting-medical-info',
    component: () => import('@views/collaborators/collaborator-medical-info.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
      ],
    },
  },
  {
    path: '/asepeyo-reporting-site/:year',
    name: 'asepeyo-reporting-site',
    component: () => import('@views/reporting/asepeyo-reporting-by-site.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/asepeyo-reporting-collaborator-site/:site/:year',
    name: 'asepeyo-reporting-collaborator-site',
    component: () => import('@views/reporting/asepeyo-reporting-collaborator-site.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/asepeyo-reporting-collaborator-director',
    name: 'asepeyo-reporting-collaborator-director',
    component: () => import('@views/reporting/aepeyo-reporting-director.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.security,
      ],
    },
  },
  {
    path: '/rp-reporting-community/:comunityId',
    name: 'rp-reporting-community',
    component: () => import('@views/reporting/rp-reporting-comunity.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/rp',
    name: 'rp',
    component: () => import('@views/rp/rp-list.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.director],
    },
  },
  {
    path: '/rp/:id/edit',
    name: 'rp-edit',
    component: () => import('@views/rp/rp-show.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/rp/create',
    name: 'rp-create',
    component: () => import('@views/rp/rp-create.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/rp/:id',
    name: 'rp-show',
    component: () => import('@views/rp/rp-show.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.director],
    },
  },
  {
    path: '/position',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
    children: [
      {
        path: 'assignment-rps',
        name: 'position-assignment-rps',
        component: () => import('@views/positions/position-assignment-rps.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
    children: [
      {
        path: '/dataControl',
        name: 'dataControl',
        component: () => import('@views/admin/data-control.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/auth/loginFedId.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          store
            .dispatch('auth/sendToken', { code: routeTo.query.code })
            .then(() => {
              next()
            })
            .catch((error) => console.warn(error))
        }
      },
    },
  },
  {
    path: '/loginFedId',
    name: 'loginFedId',
    component: () => import('@views/auth/login.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          store
            .dispatch('auth/getFedIdUrl')
            .then((response) => {
              window.location = response.data.url
            })
            .catch((error) => console.warn(error))
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@views/auth/logout.vue'),
    meta: {
      authRequired: true,
      authorize: [],
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('auth/logOut')
          .then(() => {
            next()
          })
          .catch((error) => console.warn(error))
      },
    },
  },
  {
    path: '/destroyUser',
    name: 'destroyUser',
    meta: {
      authRequired: true,
      authorize: [],
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('auth/destroyUser')
          .then(() => {
            store
                .dispatch('auth/getFedIdUrl')
                .then((response) => {
                  window.location = response.data.url
                })
                .catch((error) => console.warn(error))
          })
          .catch((error) => console.warn(error))
      },
    },
  },
  {
    path: '/collaborator-not-found',
    name: 'collaboratorNotFound',
    component: () => import('@views/auth/collaboratorNotFound.vue'),
    meta: {
      authRequired: false,
      authorize: [],
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('auth/logOut')
          .then(() => {
            next()
          })
          .catch((error) => console.warn(error))
      },
    },
  },
  {
    path: '/not-authorized',
    name: 'notAuthorized',
    component: () => import('@views/auth/notAuthorized.vue'),
    meta: {
      authRequired: true,
      authorize: [],
      // beforeResolve(routeTo, routeFrom, next) {
      //   store
      //     .dispatch('auth/logOut')
      //     .then(() => {
      //       next()
      //     })
      //     .catch((error) => console.warn(error))
      // },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '/404',
    redirect: '404',
  },
]
