import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    collaboratorRp: {},
    iconLoading: true,
  }
}

export const mutations = {}

export const state = getDefaultState()

export const actions = {
  // NEW ASSIGNMENT NEW RPS TO COLLABORATOR
  // POST
  async assignmentRpsToCollaborator(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaboratorRp.assignment`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/collaboratorRp')
    }
  },

  async updateCollaboratorRpStatus(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaboratorRp.update`
      const response = await axios.post(url, payload)

      store.commit('common/updateIconLoading', false, { root: true })
      return response.data
    } catch (error) {
      throw new Error('API Error occurred at /api/v1/collaboratorRp.update')
    }
  },

  // Unassing Rp to collaborator
  async unassignedCollaboratorRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaboratorRp.unassing`
      const response = await axios.post(url, payload)

      store.commit('common/updateIconLoading', false, { root: true })
      return response.data
    } catch (error) {
      throw new Error('API Error occurred at /api/v1/collaboratorRp.update')
    }
  },
  // edit collaborator-rp register
  async editCollaboratorRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaboratorRp.edit`
      const response = await axios.post(url, payload)

      store.commit('common/updateIconLoading', false, { root: true })
      return response
    } catch (error) {
      throw new Error('API Error occurred at /api/v1/collaboratorRp.update')
    }
  },
}
