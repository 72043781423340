import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    rps: [],
    rp: null,
    assignmentTypesRp: [],
    iconLoading: true,
    notArchivedRps: [],
  }
}

export const mutations = {
  updateRps(state, rps) {
    state.rps = rps
  },
  updateRp(state, rp) {
    let obligatoryPositionList = []
    let optionalPositionList = []

    obligatoryPositionList = rp.positionsRelated
        // eslint-disable-next-line
      .map((element) => {
        if (element.assignmentRpType.id === 1) {
          return element.position.id
        }
      })
      .filter((element) => {
        return element !== undefined
      })

    optionalPositionList = rp.positionsRelated
        // eslint-disable-next-line
      .map((element) => {
        if (element.assignmentRpType.id === 2) {
          return element.position.id
        }
      })
      .filter((element) => {
        return element !== undefined
      })

    rp.positionsRelated = obligatoryPositionList
    rp.positionsRelatedOptional = optionalPositionList

    state.rp = rp
  },
  clearRp(state) {
    state.rp = null
  },
  updateAssignmentTypesRp(state, assignmentTypesRp) {
    state.assignmentTypesRp = assignmentTypesRp
  },
  updateRpsBySite(state, notArchivedRps) {
    state.notArchivedRps = notArchivedRps
  },
}

export const state = getDefaultState()

export const actions = {
  // get all RP
  async fetchRps(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      // let queryString = `?sort=name&page[size]=${payload.pageSize}&page[number]=${payload.pageNumber}`

      // if (payload.key) {
      //   queryString = encodeURI(
      //     queryString + `&filter[${payload.key}]=${payload.value}`
      //   )
      // }

      // const url = `${BASE}/api/v1/rp.index${queryString}`
      const url = `${BASE}/api/v1/rp.index`
      const response = await axios.get(url)
      store.commit('common/updateIconLoading', false, { root: true })
      store.commit('updateRps', response)
    } catch (error) {
      throw new Error('API Error occurred at api/rp index.')
    }
  },
  // get on RP by id
  async fetchRpById(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/rp.show`
      const response = await axios.post(url, { id: payload })

      store.commit('common/updateIconLoading', false, { root: true })

      store.commit('updateRp', response.data)
    } catch (error) {
      console.error(error)
      throw new Error('API Error occurred at api/rp index.')
    }
  },

  // delete rp
  async deleteRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/rp.delete`

      const response = await axios.delete(url, { data: { id: payload.id } })

      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (error) {
      throw new Error('API Error occurred at api/rp delete.')
    }
  },
  // create rp
  async createRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/rp.create`

      const carouselTextLoading = [
        'Por favor, espere',
        'No cierre esta página',
        'Procesando datos',
        'Ejecutando procesos',
        'Esto procedimiento puede tardar unos minutos, mantengase a la espera y no cierre esta página',
      ];

      carouselTextLoading.forEach((element, index) => {
        setTimeout(function () {
          store.commit('common/setTextLoading', element, {
            root: true,
          })
        }, index * 10000)
      })

      const response = await axios.post(url, payload, {
        timeout: 0,
      })

      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (error) {
      // This response  can gives a TIME OUT . The front reacts as a edited RP and the php script continues its process
      store.commit('common/updateIconLoading', false, { root: true })

      return 'RP created'
      // throw new Error('API Error occurred at api/rp create.')
    }
  },
  async editRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      // store.commit('common/updateCarousel', true, { root: true })

      const url = `${BASE}/api/v1/rp.edit`

      const carouselTextLoading = [
        'Por favor, espere',
        'No cierre esta página',
        'Procesando datos',
        'Ejecutando procesos',
        'Esto procedimiento puede tardar unos minutos, mantengase a la espera y no cierre esta página',
      ];

      carouselTextLoading.forEach((element, index) => {
        setTimeout(function () {
          store.commit('common/setTextLoading', element, {
            root: true,
          })
        }, index * 10000)
      })

      const response = await axios.post(url, payload, {
        timeout: 0,
      })

      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (error) {
      // This response  can gives a TIME OUT . The front reacts as a edited RP and the php script continues its process
      store.commit('common/updateIconLoading', false, { root: true })

      return 'RP edited'
      // throw new Error('API Error occurred at api/rp edit.')
    }
  },
  // SEARCH RP
  // POST
  async searchRpBD(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/rps.search?key=${payload.key}&search=${payload.value}`
      )
      store.commit('common/updateIconLoading', false, { root: true })
      store.commit('updateRps', response)
      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },
  async fetchRpsNotAssignedToCollaborator(store, payload) {
    try {
      // store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.get(
        `${BASE}/api/v1/rp.getRpsNotAssignedToCollaborator/${payload}`
      )
      store.commit('updateRps', response)

      // store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

  async getAssignmentTypesRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.get(`${BASE}/api/v1/rp.getAssignmentTypesRp`)
      store.commit('updateAssignmentTypesRp', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

  async fetchRpsNotAssignedToPosition(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/rp.getNotAssignedToPosition`,
        payload
      )

      store.commit('updateRps', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

  async fetchNotArchivedRps(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.get(`${BASE}/api/v1/rp.notArvivedRps`)

      store.commit('updateRpsBySite', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },
}
