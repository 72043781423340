import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    validateRp: [],
    iconLoading: true,
  }
}

export const mutations = {}

export const state = getDefaultState()

export const actions = {
  async validateRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaboratorRp.validate`
      const response = await axios.post(url, payload)

      store.commit('common/updateIconLoading', false, { root: true })
      return response.data
    } catch (error) {
      throw new Error('API Error occurred at api/rp validate.')
    }
  },
  async refreshRpBdnApi(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaboratorRp.callBdn`
      const response = await axios.post(url, payload)

      store.commit('common/updateIconLoading', false, { root: true })
      return response.data
    } catch (error) {
      throw new Error('API Error occurred at api/rp validate.')
    }
  },

  // Multiple rp validation
  async multipleValidationRp(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaboratorRp.multiple-validation`
      const response = await axios.post(url, payload)

      store.commit('common/updateIconLoading', false, { root: true })
      return response
    } catch (error) {
      throw new Error('API Error occurred at /api/v1/collaboratorRp.update')
    }
  },
}
