import { AbilityBuilder } from '@casl/ability'

export default function defineRulesFor(userRoles, currentUser) {
  const { can, cannot, rules } = new AbilityBuilder()

  if (userRoles) {
    const prlRoles = userRoles.map((userRole) => userRole.role.code)
    if (prlRoles.includes('ADMINISTRATOR')) {
      can('manage', 'all')
      // Site privileges
      can('manage', 'site')
      // Collaborator privileges
      can('manage', 'collaborator')
      can('register', 'collaborator')
      can('updateData', 'collaborator')
      // Rp privileges
      can('manage', 'rp')
      can('validate', 'rp')
      can('sign', 'pr')
      can('manageSign', 'pr')
      can('assign', 'rp-collaborator')
      can('started', 'rp-collaborator')
      can('approve', 'rp-collaborator')
      can('edit', 'rp-collaborator')
      // Position privileges
      can('manage', 'position')
      can('assign', 'position-rp')
      // See alert-widget
      // reportin privileges
      can('manage', 'reporting')
      can('manage', 'reportingAdmin')
      cannot('manage', 'reportingDirSec')
      cannot('see', 'alerts-widget')
      // personal data
      can('manage', 'ownPersonalData')
      // admin pages
      can('manage', 'adminPages')
    } else if (prlRoles.includes('DIRECTOR')) {
      // Site privileges
      can('manage', 'site')
      // Collaborator privileges
      can('manage', 'collaborator')
      can('register', 'collaborator')
      cannot('updateData', 'collaborator')
      // Rp privileges
      can('manage', 'rp')
      can('validate', 'rp')
      can('sign', 'pr')
      can('assign', 'rp-collaborator')
      can('started', 'rp-collaborator')
      can('approve', 'rp-collaborator')
      can('edit', 'rp-collaborator')
      can('manageSign', 'pr')
      cannot('edit', 'rp')
      cannot('delete', 'rp')
      // Position privileges
      cannot('manage', 'position')
      cannot('assign', 'position-rp')
      // Rp expires widget
      can('see', 'alerts-widget')
      // roles privileges
      can('change', 'role')
      // reportin privileges
      can('manage', 'reporting')
      can('manage', 'reportingDirSec')
      cannot('manage', 'reportingAdmin')
      // personal data
      can('manage', 'ownPersonalData')
      // admin pages
      cannot('manage', 'adminPages')
    } else if (prlRoles.includes('SECURITY')) {
      // Site privileges
      cannot('manage', 'site')
      // Collaborator privileges
      can('manage', 'collaborator')
      cannot('updateData', 'collaborator')
      // Rp privileges
      can('manageSign', 'pr')
      can('validate', 'rp')
      can('sign', 'pr')
      can('assign', 'rp-collaborator')
      can('started', 'rp-collaborator')
      can('approve', 'rp-collaborator')
      can('edit', 'rp-collaborator')
      cannot('edit', 'rp')
      cannot('delete', 'rp')
      // Position privileges
      cannot('assign', 'position')
      // Rp expires widget
      can('see', 'alerts-widget')
      // roles privileges
      cannot('change', 'role')
      // reportin privileges
      can('manage', 'reporting')
      can('manage', 'reportingDirSec')
      cannot('manage', 'reportingAdmin')
      // admin pages
      cannot('manage', 'adminPages')
    } else if (prlRoles.includes('RESPONSIBLE')) {
      // Site privileges
      // Collaborator privileges
      can('manage', 'collaborator')
      cannot('delete', 'collaborator')
      cannot('updateData', 'collaborator')
      // Rp privileges
      can('manageSign', 'pr')
      can('validate', 'rp')
      can('sign', 'pr')
      can('assign', 'rp-collaborator')
      can('started', 'rp-collaborator')
      can('approve', 'rp-collaborator')
      cannot('edit', 'rp')
      cannot('delete', 'rp')
      // Position privileges
      cannot('assign', 'position')
      // Rp expires widget
      can('see', 'alerts-widget')
      // roles privileges
      cannot('change', 'role')
      // reportin privileges
      cannot('manage', 'reporting')
      cannot('manage', 'reportingDirSec')
      cannot('manage', 'reportingAdmin')
      // admin pages
      cannot('manage', 'adminPages')
    } else if (prlRoles.includes('COLLABORATOR')) {
      // Site privileges
      cannot('manage', 'site')
      // Collaborator privileges
      cannot('manage', 'collaborator')
      cannot('delete', 'collaborator')
      cannot('updateData', 'collaborator')
      // Rp privileges
      cannot('sign', 'pr')
      cannot('manageSign', 'pr')
      cannot('manage', 'rp')
      cannot('validate', 'rp')
      cannot('edit', 'rp')
      cannot('delete', 'rp')
      can('self-assign', 'rp-collaborator')
      cannot('assign', 'rp-collaborator')
      cannot('started', 'rp-collaborator')
      cannot('approve', 'rp-collaborator')
      // Position privileges
      cannot('manage', 'position')
      cannot('assign', 'position-rp')
      // Rp expires widget
      cannot('see', 'alerts-widget')
      // roles privileges
      cannot('change', 'role')
      // reportin privileges
      cannot('manage', 'reporting')
      cannot('manage', 'reportingDirSec')
      cannot('manage', 'reportingAdmin')
      // admin pages
      cannot('manage', 'adminPages')
    }
  }

  return rules
}
