// import { faDumpster } from '@/node_modules/@fortawesome/free-solid-svg-icons/index'
import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    iconLoading: true,
    autonomousCommunities: [],
  }
}

export const mutations = {
  updateAutonomousCommunities(state, autonomousCommunities) {
    state.autonomousCommunities = autonomousCommunities
  },
}

export const state = getDefaultState()

export const actions = {
  // COLLABORATOR RP EXPIRES
  // GET
  async getAutonomousCommunities(store, site) {
    const url = `${BASE}/api/v1/site.AutonomousCommunityIndex`
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.get(url)
      if (response.data.error) {
        store.commit('updateAutonomousCommunities', [])
      } else {
        store.commit('updateAutonomousCommunities', response.data)
      }
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error.')
    }
  },
}
