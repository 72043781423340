import { createI18n } from 'vue-i18n'
import messages from './messages'

const lang = localStorage.getItem('lang') || 'es'

const i18n = createI18n({
  locale: lang,
  messages,
})

export default i18n
