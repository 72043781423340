<script>
import AnonymousNavBar from '@components/nav-bars/anonymous-nav-bar/anonymous-nav-bar.vue'
export default {
  components: { AnonymousNavBar },
}
</script>

<template>
  <div :class="$style.container">
    <AnonymousNavBar :class="$style.colorNav" />
    <div style="margin-top: 100px">
    <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  min-width: $size-wizard-content-width-min;
  max-width: $size-wizard-content-width-max;
  padding: 20px;
  margin: 0 auto;
}
.colorNav {
  @extend %colorNav;
}
</style>
