<script>
import {CircleClose} from "@element-plus/icons-vue";

export default {
  components: {CircleClose},
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick();
      }
    }
  }
}
</script>

<template>
  <el-link
    :class="$style.resetButton"
    :underline="false"
    @click="handleClick"
    >
    <el-icon class="icon-closed">
    <CircleClose />
  </el-icon>
    {{ $t('message.reset') }}
  </el-link
  >
</template>

<style lang="scss" module>
@import '@design';

.resetButton {
  @extend %resetButton;
}
.resetButton:hover {
  color: $color-danger-red !important;
}

</style>
<style scoped>

.icon-closed{
  margin-right: 7px!important;
  margin-left: 7px!important;
}
</style>
