const getDefaultState = () => {
  return {
    iconLoading: true,
    textLoading: 'Cargando datos',
  }
}

export const state = getDefaultState()

export const mutations = {
  updateIconLoading(state, iconLoading) {
    state.iconLoading = iconLoading
  },
  setTextLoading(state, textLoading) {
    state.textLoading = textLoading
  },
}

export const actions = {}

export const assignmentRpTypes = {
  ASSIGNMENT_TYPE_OBLIGATORY: 'ASSIGNMENT_TYPE_OBLIGATORY',
  ASSIGNMENT_TYPE_OPTIONAL: 'ASSIGNMENT_TYPE_OPTIONAL',
}

export const rpStatus = [
  'STATUS_PENDING',
  'STATUS_COLLABORATOR_VALIDATED',
  'STATUS_LOGALTY_SENT',
  // 'STATUS_LOGALTY_APPROVED',
  'STATUS_END',
]

export const assignmentRpTypesId = {
  ASSIGNMENT_TYPE_OBLIGATORY_ID: 1,
}

export const aplicationRoles = {
  ROLE_ADMINISTRATOR: 'ADMINISTRATOR',
  ROLE_DIRECTOR: 'DIRECTOR',
  ROLE_SECURITY: 'SECURITY',
  ROLE_RESPONSIBLE: 'RESPONSIBLE',
  ROLE_COLLABORATOR: 'COLLABORATOR'
}

export const SAP = 'sap'
export const USERNAME = 'username'
export const DOCUMENT_NUMBER = 'documentNumber'
export const NAME_AND_SURNAMES = 'nameAndSurnames'

export const COLOR_RED_GRAPHICS = '#fa0000'
export const COLOR_GREEN_GRAPHICS = '#02be8a'
