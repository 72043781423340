<template>
  <el-button :class="$style.backButton" @click="$router.go(-1)">{{
    $t('message.back')
  }}</el-button>
</template>

<style lang="scss" module>
@import '@design';

.backButton {
  @extend %backButton;
}
</style>
