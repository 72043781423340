import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    roles: [],
    iconLoading: true,
  }
}

export const mutations = {
  updateCollaboratorRole(state, roles) {
    state.roles = roles
  },
}

export const state = getDefaultState()

export const actions = {
  // GET ALL ROLES THAN COLLABORATOR REGISTERED CAN ASSIGN
  async getRoles(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/actor/roles`
      const response = await axios.get(url)

      store.commit('updateCollaboratorRole', response.data)
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/role.')
    }
  },
  // NEW ASSIGNMENT NEW ROLE TO COLLABORATOR
  // POST
  async changeRoleToCollaborator(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/actor/change-roles`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })
      return response.data
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/change-role')
    }
  },
}
