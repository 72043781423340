import axios from '@utils/client'
import groupBy from "lodash/groupBy";

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    sites: null,
    sitesAll: [],
    patrons: [],
    siteOfCollaborators: null,
  }
}
export const state = getDefaultState()

export const getters = {
  groupedSites(state) {
    if (state.sites) {
      return groupBy(state.sites, 'region.name');
    }
    return {};
  },
}

export const mutations = {
  UPDATE_SITES_FOR_MAP(state, sites) {
    state.sites = sites
  },
  UPDATE_SITES(state, sitesAll) {
    state.sitesAll = sitesAll
  },
  // UPDATE_HR_REGIONS(state, hrRegions) {
  //   state.hrRegions = hrRegions
  // },
  UPDATE_PATRONS(state, patrons) {
    state.patrons = patrons
  },
  RESET_SITE_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  // SITES
  // GET
  async fetchSitesForMap(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const res = await axios.get(`${BASE}/api/v1/site.maplist`)
      store.commit('UPDATE_SITES_FOR_MAP', res.data)
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v1/site.maplist ${error}`
      )
    }
  },
  // GET SITES ALL
  // GET
  async fetchSites(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const res = await axios.get(`${BASE}/api/v1/site.enabled`)
      store.commit('UPDATE_SITES', res.data)
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error(`API Error occurred. ${BASE}/api/v2/sites ${error}`)
    }
  },
}
