/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import router from "@router";

// If authorization header was lost, it is regenerated if possible
if (
  axios &&
  axios.hasOwnProperty('defaults') &&
  axios.defaults.hasOwnProperty('headers') &&
  !axios.defaults.headers.common['Authorization'] &&
  localStorage.getItem('auth.token')
) {
  const token = JSON.parse(localStorage.getItem('auth.token'))
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}
const instance= axios.create({
  baseURL: process.env.VUE_APP_PUBLIC_URL,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
  validateStatus: (errorCode) => errorCode!==401
  })
instance.interceptors.response.use(function(config) {
    return config;
  }, function(err) {
    if (err.response.status === 401){
      if (err.response.data.code === 421){
        router.push({ name: 'destroyUser' })
      }
      if (err.response.data.code === 422){
        router.push({ name: 'collaboratorNotFound' })

      }
    }
    return Promise.reject(err);

})
export default instance;