import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    categoryList: [],
    iconLoading: true,
  }
}

export const mutations = {
  updateRpcategoryTypes(state, categoryList) {
    state.categoryList = categoryList
  },
}

export const state = getDefaultState()

export const actions = {
  // get all rpCategories
  async fetchRpCategories(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/rp.categories`
      const response = await axios.get(url)

      store.commit('updateRpcategoryTypes', response.data)
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/rpCategory index.')
    }
  },
}
