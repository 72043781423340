/* eslint-disable */
export class User {
  constructor(
    aud,
    auth_time,
    c,
    email,
    exp,
    familyName,
    federation_idp,
    givenName,
    iat,
    iss,
    jti,
    mail,
    name,
    objectclass,
    piSri,
    preferredLanguage,
    sn,
    sub,
    uid,
    uuid
  ) {
    this.aud = aud
    this.auth_time = auth_time
    this.c = c
    this.email = email
    this.exp = exp
    this.familyName = familyName
    this.federation_idp = federation_idp
    this.givenName = givenName
    this.iat = iat
    this.iss = iss
    this.jti = jti
    this.mail = mail
    this.name = name
    this.objectclass = objectclass
    this.piSri = piSri
    this.preferredLanguage = preferredLanguage
    this.sn = sn
    this.sub = sub
    this.uid = uid
    this.uuid = uuid
  }
}
/* eslint-enable */
