<script>
export default {
  data() {
    return {
      loading: true,
    }
  },
}
</script>

<template>
  <div :class="$style.loading">
    <div v-loading="loading" />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}
</style>
