import { createApp } from 'vue'
import App from './app.vue'
import router from '@router'
import store from '@state/store'
import registerBaseComponents from '@components/_globals'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import i18n from './locale/index'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './design/element-variables.scss'
import es from 'element-plus/es/locale/lang/es.mjs'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import vueSvgPlugin from './plugin/vueSvgPlugin'
import '@vtmn/icons/dist/vitamix/font/vitamix.css'
import { abilitiesPlugin } from '@casl/vue'
import { AbilityBuilder, Ability  } from '@casl/ability'
import defineRulesFor from '@utils/ability'
import VueApexCharts from "vue3-apexcharts";
import VueSignaturePad from 'vue-signature-pad'

const app = createApp(App)

registerBaseComponents(app);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(vueSvgPlugin)
app.use(ElementPlus, { es })
app.use(store)
app.use(router)
app.use(VueApexCharts)
app.use(i18n)



const { rules } = new AbilityBuilder()
const ability = new Ability(rules)
const userRoles = JSON.parse(localStorage.getItem('auth.actorRoles'));
const currentUser = JSON.parse(localStorage.getItem('auth.currentUser'));
ability.update(defineRulesFor(userRoles, currentUser))

app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

app.use(VueSignaturePad)

// Don't warn about using the dev version of Vue in development.
app.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  app.config.errorHandler = window.Cypress.cy.onUncaughtException
}

app.mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
