import axios from '@utils/client'
import { User } from '@state/infrastructure/User'
import { jwtDecode } from "jwt-decode";

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

export const state = {
  currentUser: getSavedState('auth.currentUser'),
  authToken: getSavedState('auth.token'),
  idToken: getSavedState('auth.idToken'),
  actorRoles: getSavedState('auth.actorRoles'),
  fedIdUser: null,
  collaboratorSiteAssignment: getSavedState('auth.collaboratorSiteAssignment'),
  realActorRoles: getSavedState('auth.realActorRoles')
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
}

export const mutations = {
  updateUser(state, fedIdUser) {
    state.fedIdUser = fedIdUser
  },
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  SET_AUTH_TOKEN(state, token) {
    state.authToken = token
    saveState('auth.token', token)
  },
  SET_ID_TOKEN(state, idToken) {
    state.idToken = idToken
    saveState('auth.idToken', idToken)
  },
  SET_ACTOR_ROLES(state, actorRoles) {
    state.actorRoles = actorRoles
    saveState('auth.actorRoles', actorRoles)
  },
  SET_COLLABORATOR_SITE_ASSIGNMENT(state, collaboratorSiteAssignment){
    state.collaboratorSiteAssignment = collaboratorSiteAssignment
    saveState('auth.collaboratorSiteAssignment', collaboratorSiteAssignment)
  },
  SET_REAL_ACTOR_ROLE(state, realActorRoles){
    state.realActorRoles = realActorRoles
    saveState('auth.realActorRoles', realActorRoles)
  },
}

export const actions = {
  async getFedIdUrl() {
    try {
      return await axios.get(
        `${BASE}/api/v1/actor/idpDecathlonOxylane/getLoginGateway`
      )
    } catch (error) {
      throw new Error(
        `API Error occurred at /api/v1/common/idpDecathlonOxylane/getLoginGateway`
      )
    }
  },

  async sendToken({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/api/v1/actor/idpDecathlonOxylane/getJwtTokenFromCode`,
        payload
      )
      const fedIdUser = jwtDecode(response.data.idToken)
      const AUTH_TOKEN = response.data.accessToken
      const ID_TOKEN = response.data.idToken
      commit('updateUser', fedIdUser)
      commit('SET_AUTH_TOKEN', AUTH_TOKEN)
      commit('SET_ID_TOKEN', ID_TOKEN)

      const token = JSON.parse(localStorage.getItem('auth.token'))
      if (token) {
        // eslint-disable-next-line
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }

      return fedIdUser
    } catch (e) {
      throw Error(
        `Auth error ocurred in ${BASE}/api/v1/actor/idpDecathlonOxylane/getJwtTokenFromCode ${e}`
      )
    }
  },

  logInFedId({ commit, dispatch, getters }, fedIdUser) {
    if (getters.loggedIn) return dispatch('validate')
    const user = fedIdUser.user
    commit('SET_CURRENT_USER', user)
  },

  async fetchSelfProfile({ commit }) {
    try {
      const response = await axios.get(
        // `${BASE}/${LOCALE}/api/v1/collaborator/profile`
        `${BASE}/api/v1/actor/profile`
      )
      const ACTOR_ROLES = response.data.actorRoles
      const COLLABORATOR_SITE_ASSIGNMENT = response.data.collaborator.collaboratorSiteAssignment
      commit('SET_ACTOR_ROLES', ACTOR_ROLES)
      commit('SET_REAL_ACTOR_ROLE', ACTOR_ROLES)
      commit('SET_COLLABORATOR_SITE_ASSIGNMENT', COLLABORATOR_SITE_ASSIGNMENT)
    } catch (error) {
      throw new Error(`API Error occurred at /api/v1/actor/profile`)
    }
  },

  // Logs out the current user.
  async logOut({ dispatch }) {
    try {
      const payload = { idToken: state.idToken }
      const response = await axios.post(
        `${BASE}/api/v1/actor/idpDecathlonOxylane/revokeSession`,
        payload
      )
      dispatch('destroyUser')
      return response
    } catch (e) {
      throw Error(
        `Auth error ocurred in ${BASE}/api/v1/actor/idpDecathlonOxylane/revokeSession`
      )
    }
  },
  destroyUser({ commit }){
    commit('SET_CURRENT_USER', null)
    commit('SET_AUTH_TOKEN', null)
    commit('SET_ID_TOKEN', null)
    commit('SET_ACTOR_ROLES', null)
    commit('SET_COLLABORATOR_SITE_ASSIGNMENT', null)
    commit('SET_REAL_ACTOR_ROLE', null)
    // eslint-disable-next-line
    delete axios.defaults.headers.common['Authorization']
  },
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)
    const user = new User(
      state.currentUser.aud,
      state.currentUser.auth_time,
      state.currentUser.c,
      state.currentUser.email,
      state.currentUser.exp,
      state.currentUser.familyName,
      state.currentUser.federation_idp,
      state.currentUser.givenName,
      state.currentUser.iat,
      state.currentUser.iss,
      state.currentUser.jti,
      state.currentUser.mail,
      state.currentUser.name,
      state.currentUser.objectclass,
      state.currentUser['pi.sri'],
      state.currentUser.preferredLanguage,
      state.currentUser.sn,
      state.currentUser.sub,
      state.currentUser.uid,
      state.currentUser.uuid
    )
    if (
      state.currentUser.uuid !== user.uuid ||
      state.currentUser.uid !== user.uid
    ) {
      commit('SET_CURRENT_USER', null)
      commit('SET_AUTH_TOKEN', null)
      commit('SET_ID_TOKEN', null)
      commit('SET_ACTOR_ROLES', null)
    } else {
      return Promise.resolve(user)
    }
  },
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
