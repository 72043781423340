import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const authMethods = mapActions('auth', ['logOut', 'logInFedId'])

export const siteClearance = {
  admin: ['ADMINISTRATOR'],
  director: ['DIRECTOR'],
  security: ['SECURITY'],
  responsible: ['RESPONSIBLE'],
  collaborator: ['COLLABORATOR'],
}
