import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    directorsWithoutSignature: [],
    collaboratorsWithoutProfile: [],
    collaboratorsWithoutSap: [],
    collaboratorsWithoutMemberId: [],
    iconLoading: true,
    textLoading: 'Cargando datos',
    collaboratorsWithoutValidator: [],
    directorAndCenterData: [],
    sitesDefaultSigner: [],
  }
}

export const state = getDefaultState()

export const mutations = {
  updateDirectorWithoutSignature(state, directorsWithoutSignature) {
    state.directorsWithoutSignature = directorsWithoutSignature
  },
  updateCollaboratorsWithoutProfile(state, collaboratorsWithoutProfile) {
    state.collaboratorsWithoutProfile = collaboratorsWithoutProfile
  },
  updateCollaboratorsWithoutSap(state, collaboratorsWithoutSap) {
    state.collaboratorsWithoutSap = collaboratorsWithoutSap
  },
  updateCollaboratorsWithoutMemberId(state, collaboratorsWithoutMemberId) {
    state.collaboratorsWithoutMemberId = collaboratorsWithoutMemberId
  },
  updateCollaboratorsWithoutValidator(state, collaboratorsWithoutValidator) {
    state.collaboratorsWithoutValidator = collaboratorsWithoutValidator
  },
  updateDirectorAndCenterData(state, directorAndCenterData) {
    state.directorAndCenterData = directorAndCenterData
  },
  updateSitesDefaultSigner(state, sitesDefaultSigner) {
    state.sitesDefaultSigner = sitesDefaultSigner
  },
}

export const actions = {
  // COLLABORATOR
  // GET
  async getDirectorsWithoutSignature(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/directors.without.signature`
      const response = await axios.get(url)

      store.commit('updateDirectorWithoutSignature', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  async getCollaboratorsWithoutProfile(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.without.profile`
      const response = await axios.get(url)

      store.commit('updateCollaboratorsWithoutProfile', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  async getCollaboratorsWithoutSap(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.without.sap`
      const response = await axios.get(url)

      store.commit('updateCollaboratorsWithoutSap', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  async getCollaboratorsWithoutMemberId(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.without.memberId`
      const response = await axios.get(url)

      store.commit('updateCollaboratorsWithoutMemberId', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  async getCollaboratorsWithoutRpValidator(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.without.validator`
      const response = await axios.get(url)

      store.commit('updateCollaboratorsWithoutValidator', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  async getDirectorAndCenterData(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/directors.center.data.get`
      const response = await axios.get(url)

      store.commit('updateDirectorAndCenterData', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  // GET SITES WITH SIGNER BY DEFAULT
  async getSitesDefaultsigner(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborator.getAllAssignSiteSigner`
      const response = await axios.get(url)

      store.commit('updateSitesDefaultSigner', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
}
