<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: null,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick();
      }
    }
  }
}
</script>

<template>
  <el-button
    v-if="name"
    :disabled="readOnly"
    type="primary"
    @click="handleClick"
    >{{ name }}</el-button
  >
  <el-button v-else :disabled="readOnly" type="primary" @click="handleClick">{{
    $t('message.submit')
  }}</el-button>
</template>

<style lang="scss" module>
@import '@design';
</style>
