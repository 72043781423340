import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    validationTypeList: [],
    iconLoading: true,
  }
}

export const mutations = {
  updateRpValidationTypes(state, validationTypeList) {
    state.validationTypeList = validationTypeList
  },
}

export const state = getDefaultState()

export const actions = {
  // get all rpValidationTypes
  async fetchRpValidationTypes(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/rpValidationType.index`
      const response = await axios.get(url)

      store.commit('updateRpValidationTypes', response.data)
      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/rpValidationType index.')
    }
  },
}
