import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    positionsList: [],
    position: null,
    iconLoading: true,
  }
}

export const mutations = {
  updatePositions(state, positionsList) {
    state.positionsList = positionsList
  },
}

export const state = getDefaultState()

export const actions = {
  // get all positions
  async fetchPositions(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/position.index`

      const response = await axios.get(url)

      store.commit('common/updateIconLoading', false, { root: true })
      store.commit('updatePositions', response.data)
    } catch (error) {
      throw new Error('API Error occurred at api/positions index.')
    }
  },
  async assignRpToPosition(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/position.assignRps`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      if (e.response.status === 500 && e.response.data.error) {
        throw new Error(e.response.data.error)
      }
      throw new Error('API Error ocurred at post api/v1/assingRps')
    }
  },
}
