<script>
import SupportIcon from "@components/nav-bars/common/support-icon.vue";

export default {
  name: 'TheNavBar',
  components: {
    SupportIcon,
  },
  // ===
  // METHODS
  // ===
  methods: {
    switchLocale(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    },
  },
}
</script>

<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark anonymousNavbar">
    <div class="container-fluid">
    <div id="navbarSupportedContent" class="collapse navbar-collapse">
    <router-link id="menuLogo" exact to="/" class="navbar-brand">
      <img
        src="../../../assets/images/logo_prl_big.png"
        class="d-inline-block align-bottom"
        alt=""
      />
    </router-link>
      <!-- SUPPORT -->
      <router-link
          id="support"
          exact to="/support"
          :aria-label="$tc('message.support')"
          class="support"
      >
        <SupportIcon/>
      </router-link>
    </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@import '@design';

.translate {
  margin: 2px;
  text-transform: capitalize;
}
.anonymousNavbar {
  display: flex;
  justify-content: space-between;
}
.dropdownMenuLeft {
  /* stylelint-disable-next-line */
  .dropdown-menu.show {
    left: -4rem !important;
  }
}
</style>
